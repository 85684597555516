import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { supabase } from './supabaseClient';
import logo from './assets/transparent-logoo.png';

import './loading.css';
const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const checkUser = async () => {
        const { data: { user } } = await supabase.auth.getUser()
        setIsAuthenticated(!!user);
        setUser(user);
      setLoading(false);
    };

    checkUser();

    // Listen for auth state changes
    const { data } = supabase.auth.onAuthStateChange((event, session) => {
      setIsAuthenticated(!!session?.user);
    });

    return () => data.subscription.unsubscribe();
  }, []);

  if (loading)
    return (<div class="logo-container">
        <img src={logo} alt="Logo" class="animated-logo" />
    </div>);

  return isAuthenticated ? React.cloneElement(children, { user }) : <Navigate to="/" />;
};

export default ProtectedRoute;
