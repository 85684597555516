import { supabase } from '../supabaseClient';

const logout = async () => {
   const { error } = await supabase.auth.signOut();
  if (error) {
    console.error('Registration Error:', error.message);
    throw error;
  } else {
    return 'Signed out!';
  }
};

export default logout;