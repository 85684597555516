import React, { useState, useEffect } from 'react';
import './App.css';
import logo from './assets/logo.webp';
import ScrollToHashElement from "@cascadia-code/scroll-to-hash-element";
import login from './auth/login-form';
import register from './auth/register-form';
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const today = new Date();
const day = String(today).slice(0, 3);

const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        toast.dismiss(); // remove the 'click the queue you want to join'
        e.preventDefault();
        try {
        // const { user } = await signInWithEmailAndPassword(auth, email, password);
            const res_msg = await login(email, password);
            toast.success(res_msg);
            navigate('/dashboard');
        } catch (error) {
            toast.error(error.message);
        }
        // e.preventDefault();
    };
    const handleInvalid = (e) => {
        e.preventDefault();
        toast.error("Email or password isn't formatted");
    }

    return (
        <div className="w-full max-w-[400px] mx-auto">

        <form className="form" onSubmit={handleSubmit}>
        <input
            className="w-full border-b border-gray-300 focus:border focus:border-gray-300 p-2 mb-10"
            placeholder="Email*"
            id="email"
            type="email"
            variant="standard"
            onChange={(e) => setEmail(e.target.value)}
            onInvalid={handleInvalid}  // Handle invalid event
            required />
        <input
            className="w-full border-b border-gray-300 focus:border focus:border-gray-300 p-2 mb-10"
            id="password"
            placeholder="Password*"
            type="password"
            variant="standard"
            onChange={(e) => setPassword(e.target.value)}
            onInvalid={handleInvalid}  // Handle invalid event
            required />
         <span className="w-full border-b border-gray-300 focus:border focus:border-gray-300 p-2 mb-10" onClick={() => toast.error("Please contact adminstration.")}style={{ cursor: 'pointer',  fontSize: '14px', marginTop: '10px', color: "black",     userSelect: 'none' }}>
            Forgot password?
        </span>
        <button type="submit" className="w-full bg-weird-yellow hover:bg-yellow-500 text-white py-3 px-8 font-expanded font-medium text-xs md:text-xl mt-6 rounded-full">
            Login
        </button>
        </form>
        </div>
    );
};

const RegisterForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [trialId, setTrialId] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    //TODO: add more
    const handleSubmit = async (e) => {
        toast.dismiss(); // remove the 'click the queue you want to join'
        e.preventDefault();
        try {
            if (password != passwordConfirmation) {
                throw new Error("Password and confirmation don't match");
            }
            const res_msg = await register(email, password, name, trialId);
            toast.success(res_msg);


        } catch (error) {
            toast.error(error.message);
        }
        // e.preventDefault();
    };
    const handleInvalid = (e) => {
        toast.dismiss(); // remove the 'click the queue you want to join'
        e.preventDefault();
        toast.error("Invalid input");
    }
  return (
    <div className=" w-full max-w-[400px] mx-auto ">
    <ScrollToHashElement behavior="smooth" inline="center" block="center"/>
    <form className="form" onSubmit={handleSubmit} >
      <input
        className="w-full border-b border-gray-300 focus:border focus:border-gray-300 p-2 mb-10"
        id="email"
        value={email}
        placeholder="Email*"
        type="email"
        variant="standard"
        onChange={(e) => setEmail(e.target.value)}
        onInvalid={handleInvalid}  // Handle invalid event
        required/>
      <input
        className="w-full  border-b border-gray-300 focus:border focus:border-gray-300 p-2 mb-10"
        placeholder="Full Name*"
        id="name"
        value={name}
        type="text"
        variant="standard"
        onChange={(e) => setName(e.target.value)}
        onInvalid={handleInvalid}  // Handle invalid event
        required/>
      <input
        className="w-full border-b border-gray-300 focus:border focus:border-gray-300 p-2 mb-10"
        id="id"
        placeholder="Clinical Trial ID*"
        value={trialId}
        type="text"
        variant="standard"
        onChange={(e) => setTrialId(e.target.value)}
        onInvalid={handleInvalid}  // Handle invalid event
        required/>
      <input
        className="w-full border-b border-gray-300 focus:border focus:border-gray-300 p-2 mb-10"
        id="password"
        placeholder="Password*"
        value={password}
        type="password"
        variant="standard"
        onChange={(e) => setPassword(e.target.value)}
        onInvalid={handleInvalid}  // Handle invalid event
        required/>
      <input
        className="w-full border-b border-gray-300 focus:border focus:border-gray-300 p-2 mb-10"
        id="confirm"
        placeholder="Confirm Password*"
        value={passwordConfirmation}
        type="password"
        variant="standard"
        onChange={(e) => setPasswordConfirmation(e.target.value)}
        onInvalid={handleInvalid}  // Handle invalid event
        required/>
      <button type="submit" className="button w-full bg-weird-yellow hover:bg-yellow-500 text-white py-3 px-8 font-expanded font-medium text-xs md:text-xl mt-6 rounded-full">
        Register
      </button>
      </form>

    </div>
  );
};


function App() {
  const [view, setView] = useState('login'); // State to toggle between 'login' and 'register'

  return (
    <div>

    <ScrollToHashElement behavior="smooth" inline="center" block="center"/>
    <div className="flex flex-row justify-between px-12 p-4 sticky top-0 bg-white w-full z-20">
    <a href="https://cureva.co" ><img className="w-36" src={logo} alt="logo" /> </a>
    </div>
    <div className="App  pt-40 flex-col  min-h-screen">
      <Toaster/>
      <div className="flex justify-center mb-4">
        <button
          className={`mr-6 ${view === 'login' ? 'font-bold' : ''} text-left text-xl mb-12`}
          onClick={() => setView('login')}
        >
          Login
        </button>
        <button
          className={`${view === 'register' ? 'font-bold' : ''} text-left text-xl mb-12`}
          onClick={() => setView('register')}
        >
          Register
        </button>
      </div>
      <div className="form-container flex flex-col space-y-6">
        {view === 'login' ? <LoginForm /> : <RegisterForm />}
      </div>

    </div>
    </div>


  );
}

export default App;