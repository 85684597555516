import Header from './components/Header/Header';
import ScrollToHashElement from "@cascadia-code/scroll-to-hash-element";
import React, { useEffect, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import PatientTable from './PatientTable';

function DynamicText({name}) {

    const text = `Hi ${name}. Access is in review. Our team will get back to you shortly...`;
    const [displayedText, setDisplayedText] = useState("");
    const [index, setIndex] = useState(0);
    useEffect(() => {
        if (index < text.length) {
          const timeout = setTimeout(() => {
            setDisplayedText((prev) => prev + text[index]);
            setIndex(index + 1);
          }, 50); // Adjust the speed of typing here
          return () => clearTimeout(timeout);
        }
      }, [index, text]);
    return (
        <div className="App  pt-40 flex-col  min-h-screen">
            {displayedText}
        </div>
    );
}
function getFirstName(fullName) {
    // Check if fullName is a valid string and not empty
    if (typeof fullName !== 'string' || fullName.trim() === '') {
      return ''; // Return an empty string if the name is invalid
    }
    // Split the name by spaces and return the first part
    const nameParts = fullName.trim().split(/\s+/); // `\s+` handles multiple spaces
    return nameParts[0]; // Return the first word (first name)
}
function Dashboard({ user }) {
    const firstName = getFirstName(user.user_metadata.name);
    return(
        <div>
        <Toaster/>
        <ScrollToHashElement behavior="smooth" inline="center" block="center"/>
        <Header/>
        {user.user_metadata?.access_granted ? <PatientTable name={firstName}/>: <DynamicText name={firstName}/>}
        </div>
    );
}
export default Dashboard;
