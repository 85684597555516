import { supabase } from '../supabaseClient';

const register = async (email, password, name, clinicalTrialId) => {
  const { data, error } = await supabase.auth.signUp({
    email,
    password,
    options: {
        data: {
          clinical_trial_ID: clinicalTrialId,
          name: name,
          access_granted: false
        }
    }
  });
  console.log("data", data);
  if (error) {
    console.error('Registration Error:', error.message);
    throw error;
  } else {
    return 'Check your email to confirm your account!';
  }
};

export default register;