const patients = [
    { name: "Alice Johnson", phone: "(555) 123-4567", email: "alice@example.com", matchDate: "2023-06-15" },
    { name: "Bob Smith", phone: "(555) 234-5678", email: "bob@example.com", matchDate: "2023-06-18" },
    { name: "Carol Williams", phone: "(555) 345-6789", email: "carol@example.com", matchDate: "2023-06-20" },
    { name: "David Brown", phone: "(555) 456-7890", email: "david@example.com", matchDate: "2023-06-22" },
    { name: "Eva Davis", phone: "(555) 567-8901", email: "eva@example.com", matchDate: "2023-06-25" },
  ];

export default function PatientTable({name}) {
    const userName = name;
    const clinicalTrialId = "CT12345";

    return (
      <div className="container mx-auto p-4">
        <div className="mb-6 text-center">
          <h1 className="text-2xl font-bold text-orange-600 mb-2">Welcome back, {userName}</h1>
          <h2 className="text-xl font-semibold text-orange-500">Clinical Trial ID: {clinicalTrialId}</h2>
        </div>
        <div className="overflow-x-auto">
          <table className="table-auto w-full border-collapse shadow-lg bg-white rounded-lg">
            <caption className="text-orange-600 font-semibold text-lg mb-4">
              List of Matched Patients
            </caption>
            <thead className="bg-orange-100">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-orange-800 uppercase tracking-wider">
                  Patient Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-orange-800 uppercase tracking-wider">
                  Phone Number
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-orange-800 uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-orange-800 uppercase tracking-wider">
                  Date of Match
                </th>
              </tr>
            </thead>
            <tbody>
              {patients.map((patient, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-orange-50" : "bg-white"}
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-orange-900">
                    {patient.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-orange-700">
                    {patient.phone}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-orange-700">
                    {patient.email}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-orange-700">
                    {patient.matchDate}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
