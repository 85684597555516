import { supabase } from '../supabaseClient';

const login = async (email, password) => {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });

  if (error) {
    console.error('Login Error:', error.message);
    throw error;
  } else {
    return 'Login Successful!';
  }
};

export default login;