import logo from '../../assets/logo.webp';
import React from 'react'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import ScrollToHashElement from "@cascadia-code/scroll-to-hash-element";
import logout from '../../auth/logout-form';
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };


    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        toast.dismiss(); // remove the 'click the queue you want to join'
        e.preventDefault();
        try {
            const res_msg = await logout();
            toast.success(res_msg);
        } catch (error) {
            toast.error(error.message);
        }
    };
    const DrawerList = (
        <Box sx={{ width: 400 }} role="presentation" onClick={toggleDrawer(false)}>
            <ScrollToHashElement behavior="smooth" inline="center" block="center" />
            <Toaster/>
            <div className="p-14 flex flex-col gap-4 text-xl">
                <div class="w-full flex flex-row-reverse">
                    <CloseIcon className="hover:text-weird-yellow hover:cursor-pointer"/>
                </div>
                <ListItem>
                    <button onClick={handleSubmit} className="text-weird-yellow">
                        Sign Out
                    </button>
                </ListItem>
                {/* <ListItem>
                        <a href="#services" className="hover:font-bold hover:border-b hover:border-weird-yellow hover:cursor-pointer">Services</a>
                </ListItem>
                <ListItem>
                        <a href="#contact" className="hover:font-bold hover:border-b hover:border-weird-yellow hover:cursor-pointer">Contact</a>
                </ListItem> */}
                {/* <ListItem>
                        <a href="#partners" className="hover:font-bold hover:border-b hover:border-weird-yellow hover:cursor-pointer">Partners</a>
                </ListItem> */}
                {/* <ListItem>
                        <a href="#services" className="hover:font-bold hover:border-b hover:border-weird-yellow hover:cursor-pointer">API</a>
                </ListItem> */}
            </div>
        </Box>
    );

    return (
        <div className="flex flex-row justify-between px-12 p-4 sticky top-0 bg-white w-full z-20">
            <a href="https://cureva.co" ><img className="w-36" src={logo} alt="logo" /> </a>
            <div className="flex flex-rol items-center justify-center">
                <button onClick={toggleDrawer(true)}><MenuIcon /></button>
            </div>
            <Drawer open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
        </div>
    )
}

export default Header
